import React from 'react';

import ConsultantDetailView from '../../../views/admin/ConsultantDetailView';

const DetailToPrint = ({ location }: { location: Location }): React.ReactElement => {
  return (
    <ConsultantDetailView
      inputConsultantId={parseInt(location.hash.replace('#', ''))}
      hideContent={true}
    />
  );
};

export default DetailToPrint;
