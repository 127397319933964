import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { createStyles, Paper, StyleRules, WithStyles, withStyles } from '@material-ui/core';

import { loadingEndAction } from '../../reducers/loadingReducer';
import SummaryPrintView from '../consultant/verification/SummaryPrintView';

const styles = (): StyleRules =>
  createStyles({
    pdfContent: {
      margin: '0 auto',
      maxWidth: '70%',
    },
  });

const ADMIN_GET_CONSULTANT = gql`
  query QUERY($consultantId: Long!) {
    adminGetConsultantById(consultantId: $consultantId) {
      id
      verification {
        id
        consultantType
        status
        basicInfo {
          id
          avatar
          nationality
          regionProvince
          regionCity
          firstName
          lastName
          phoneNumber
          birthday
          gender
          birthYear
          birthMonth
          passportType
          passportId
          contactEmail
          nowAddressProvince
          nowAddressCity
          nowAddressDetail
          nowAddressDistrict
          personalDescription
        }
        educationInfoList {
          id
          education
          schoolName
          majorType
          academicDirections
          atSchoolDateRangeStart
          atSchoolDateRangeEnd
          educationFileList {
            id
            file
          }
        }
        workInfoList {
          id
          companyName
          wayOfWorking
          jobTitle
          industryClass
          professionalClass
          atWorkDateRangeStart
          atWorkDateRangeEnd
          jobDescription
        }
        projectInfoList {
          id
          projectDateStart
          projectCompanyName
          projectImageFileList {
            id
            file
          }
          projectDateEnd
          projectName
          projectDescription
        }
        languageInfoList {
          id
          languageLevel
          languageName
        }
        honorInfoList {
          id
          honorTitle
          honorDateRangeStart
          honorDateRangeEnd
          honorContent
        }
        certificateInfoList {
          certificateName
          certificateDescription
          certificateFileList {
            id
            file
          }
        }
        otherInfo {
          id
          background
          description
          strength
        }
        settings {
          findingJob
          id
          workDossier
          workType
          workScenario
          expectedFeePerHour
          expectedFeePerMonth
        }
      }
    }
  }
`;

const ConsultantDetailView = ({
  classes,
  inputConsultantId,
  hideContent = false,
}: Props): ReactElement => {
  const dispatch = useDispatch();

  // 顾问信息
  const [consultant, setConsultant] = useState<Consultant>();
  const domRef = useRef();

  // 页面传参返回顾问信息
  const { data: consultantData, loading: consultantLoading } = useQuery(ADMIN_GET_CONSULTANT, {
    variables: {
      consultantId: inputConsultantId,
    },
  });

  useEffect(() => {
    if (consultantData && consultantData.adminGetConsultantById) {
      setConsultant(consultantData.adminGetConsultantById);
      dispatch(loadingEndAction());
    }
  }, [consultantData]);

  useEffect(() => {
    if (!consultantLoading) {
      dispatch(loadingEndAction());
    }
  }, [consultantLoading]);

  return (
    <div className={classes.pdfDiv}>
      <Paper id="pdfContent" ref={domRef}>
        {consultant?.verification && (
          <SummaryPrintView consultant={consultant.verification} hideContent={hideContent} />
        )}
      </Paper>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  inputConsultantId: number;
  hideContent?: boolean;
}

export default withStyles(styles)(ConsultantDetailView);
